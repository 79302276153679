.stat-card-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;

  .stat-card {
    .stat-card-header {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      box-shadow: 0 0 3px 1px #f3efef;
      padding: 0.8rem;
      width: 100%;

      span {
        display: inline-block;
      }
    }

    .stat-card-divider {
      border-top: 3px solid green;
    }
  }

  .stat-card-body {
    background-color: #fff;
    box-shadow: 0 0 3px 1px #f3efef;
    padding: 0.8rem;
    width: 100%;

    .stat-card-item {
      display: flex;
      justify-content: space-between;
    }

    .stat-card-item-divider {
      border-top: 1px solid black;
    }

    span {
      display: inline-block;
    }
  }
}
