@import url(https://fonts.googleapis.com/css?family=Avenir:400,600,700);
@font-face {
  font-family: pages-icon;
  src: url(/static/media/Pages-icon095c.cd3f981d.eot);
  src: url(/static/media/Pages-icond41d.cd3f981d.eot?#iefix-u69vo5)
      format("embedded-opentype"),
    url(/static/media/Pages-icon095c.daae13ce.woff) format("woff"),
    url(/static/media/Pages-icon095c.a0ee0bee.ttf) format("truetype"),
    url(/static/media/Pages-icon095c.ff9a0e13.svg#Pages-icon) format("svg");
  font-weight: 400;
  font-style: normal;
}
.pg {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="pg-"],
[class*=" pg-"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pg-link:before {
  content: "\e900";
}
.pg-menu:before {
  content: "\e901";
}
.pg-alt_menu:before {
  content: "\e902";
}
.pg-thumbs:before {
  content: "\e903";
}
.pg-world:before {
  content: "\e904";
}
.pg-boxnet:before {
  content: "\e662";
}
.pg-signals:before {
  content: "\e61c";
}
.pg-crop:before {
  content: "\e657";
}
.pg-folder_alt:before {
  content: "\e658";
}
.pg-folder:before {
  content: "\e659";
}
.pg-theme:before {
  content: "\e65a";
}
.pg-battery_empty:before {
  content: "\e65b";
}
.pg-battery:before {
  content: "\e65c";
}
.pg-note:before {
  content: "\e65d";
}
.pg-server_hard:before {
  content: "\e65e";
}
.pg-servers:before {
  content: "\e65f";
}
.pg-menu_justify:before {
  content: "\e660";
}
.pg-credit_card:before {
  content: "\e60f";
}
.pg-fullscreen_restore:before {
  content: "\e633";
}
.pg-fullscreen:before {
  content: "\e634";
}
.pg-minus:before {
  content: "\e635";
}
.pg-minus_circle:before {
  content: "\e63a";
}
.pg-plus_circle:before {
  content: "\e656";
}
.pg-refresh_new:before {
  content: "\e600";
}
.pg-close_line:before {
  content: "\e601";
}
.pg-close:before {
  content: "\e60a";
}
.pg-arrow_down:before {
  content: "\e60b";
}
.pg-arrow_left_line_alt:before {
  content: "\e628";
}
.pg-arrow_left:before {
  content: "\e629";
}
.pg-arrow_lright_line_alt:before {
  content: "\e62a";
}
.pg-arrow_maximize_line:before {
  content: "\e62b";
}
.pg-arrow_maximize:before {
  content: "\e62c";
}
.pg-arrow_minimize_line:before {
  content: "\e62f";
}
.pg-arrow_minimize:before {
  content: "\e630";
}
.pg-arrow_right:before {
  content: "\e631";
}
.pg-arrow_up:before {
  content: "\e632";
}
.pg-more:before {
  content: "\e655";
}
.pg-bag:before {
  content: "\e602";
}
.pg-bag1:before {
  content: "\e603";
}
.pg-bold:before {
  content: "\e604";
}
.pg-calender:before {
  content: "\e605";
}
.pg-camera:before {
  content: "\e606";
}
.pg-centeralign:before {
  content: "\e607";
}
.pg-charts:before {
  content: "\e608";
}
.pg-clock:before {
  content: "\e609";
}
.pg-comment:before {
  content: "\e60c";
}
.pg-contact_book:before {
  content: "\e60d";
}
.pg-credit_card_line:before {
  content: "\e60e";
}
.pg-cupboard:before {
  content: "\e610";
}
.pg-desktop:before {
  content: "\e611";
}
.pg-download:before {
  content: "\e612";
}
.pg-eraser:before {
  content: "\e613";
}
.pg-extra:before {
  content: "\e614";
}
.pg-form:before {
  content: "\e615";
}
.pg-grid:before {
  content: "\e616";
}
.pg-home:before {
  content: "\e617";
}
.pg-image:before {
  content: "\e618";
}
.pg-inbox:before {
  content: "\e619";
}
.pg-indent:before {
  content: "\e61a";
}
.pg-italic:before {
  content: "\e61b";
}
.pg-laptop:before {
  content: "\e61d";
}
.pg-layouts:before {
  content: "\e61e";
}
.pg-layouts2:before {
  content: "\e61f";
}
.pg-layouts3:before {
  content: "\e620";
}
.pg-layouts4:before {
  content: "\e621";
}
.pg-leftalign:before {
  content: "\e622";
}
.pg-like:before {
  content: "\e623";
}
.pg-like1:before {
  content: "\e624";
}
.pg-lock:before {
  content: "\e625";
}
.pg-mail:before {
  content: "\e626";
}
.pg-map:before {
  content: "\e627";
}
.pg-menu_lv:before {
  content: "\e62d";
}
.pg-movie:before {
  content: "\e636";
}
.pg-ordered_list:before {
  content: "\e637";
  color: #231f20;
}
.pg-outdent:before {
  content: "\e638";
}
.pg-phone:before {
  content: "\e639";
}
.pg-plus:before {
  content: "\e63b";
}
.pg-power:before {
  content: "\e63c";
}
.pg-printer:before {
  content: "\e63d";
}
.pg-refresh:before {
  content: "\e63e";
}
.pg-resize:before {
  content: "\e63f";
}
.pg-right_align:before {
  content: "\e640";
}
.pg-save:before {
  content: "\e641";
}
.pg-search:before {
  content: "\e642";
}
.pg-sent:before {
  content: "\e643";
}
.pg-settings_small_1:before {
  content: "\e644";
}
.pg-settings_small:before {
  content: "\e645";
}
.pg-settings:before {
  content: "\e646";
}
.pg-shopping_cart:before {
  content: "\e647";
}
.pg-social:before {
  content: "\e648";
}
.pg-spam:before {
  content: "\e649";
}
.pg-suitcase:before {
  content: "\e64a";
}
.pg-tables:before {
  content: "\e64b";
}
.pg-tablet:before {
  content: "\e64c";
}
.pg-telephone:before {
  content: "\e64d";
}
.pg-text_style:before {
  content: "\e64e";
}
.pg-trash_line:before {
  content: "\e64f";
}
.pg-trash:before {
  content: "\e650";
}
.pg-ui:before {
  content: "\e651";
}
.pg-underline:before {
  content: "\e652";
}
.pg-unordered_list:before {
  content: "\e653";
}
.pg-video:before {
  content: "\e654";
}

body.mobile .page-sidebar .sidebar-menu .menu-items li:hover a {
  color: #788195;
}

body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a,
body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a {
  color: #000;
}

.page-sidebar {
  width: 260px;
  background-color: #fff;
  z-index: 1000;
  left: 0;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.page-sidebar a,
.page-sidebar button {
  color: #788195;
}

.page-sidebar a:hover,
.page-sidebar button:hover,
.page-sidebar a:active,
.page-sidebar button:active {
  color: #000;
  background-color: transparent !important;
}

.page-sidebar a:hover:focus,
.page-sidebar button:hover:focus,
.page-sidebar a:active:focus,
.page-sidebar button:active:focus {
  color: #000;
}

.page-sidebar a:visited,
.page-sidebar button:visited,
.page-sidebar a:focus,
.page-sidebar button:focus {
  color: #788195;
}

.page-sidebar button.sidebar-slide-toggle.active {
  color: #788195;
}

.page-sidebar button.sidebar-slide-toggle.active:focus {
  background: 0 0;
}

.page-sidebar .sidebar-header {
  display: block;
  height: 62px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  color: #232730;
  width: 100%;
  padding: 0 20px;
  padding-left: 20px;
  padding-top: 10px;
  clear: both;
  z-index: 10;
  position: relative;
}

.page-sidebar .sidebar-header .sidebar-header-controls {
  display: inline-block;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
}

.page-sidebar .sidebar-header .sidebar-slide-toggle i {
  transition: all 0.12s ease;
}

.page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
  transform: rotate(-180deg);
}

.page-sidebar .close-sidebar {
  position: absolute;
  right: 19px;
  top: 14px;
  padding: 9px;
  z-index: 1;
}

.page-sidebar .close-sidebar > i {
  color: rgba(255, 255, 255, 0.57);
}

.page-sidebar .sidebar-overlay-slide {
  width: 100%;
  height: 100%;
  background-color: #272b35;
  display: block;
  z-index: 9;
  padding: 80px 20px 20px;
}

.page-sidebar .sidebar-overlay-slide.from-top {
  top: -100%;
  position: absolute;
  transition: all 0.2s ease;
}

.page-sidebar .sidebar-overlay-slide.from-top.show {
  transform: translate(0, 100%);
}

.page-sidebar .sidebar-menu {
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
  border-right: 1px solid #f5f7f9;
}

.page-sidebar .sidebar-menu .outer-tab-nav-section {
  display: inline-block;
  width: 45px;
  position: absolute;
  height: 100%;
  background-color: #0aa699 !important;
}

.page-sidebar .sidebar-menu .menu-items {
  list-style: none;
  margin: 0;
  padding: 30px 0;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 10px);
  width: 100%;
}

.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar {
  background: lightyellow;
  width: 7px;
}
.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.page-sidebar .sidebar-menu .menu-items li:hover > a,
.page-sidebar .sidebar-menu .menu-items li.open > a,
.page-sidebar .sidebar-menu .menu-items li.active > a {
  color: #000;
}

.page-sidebar .sidebar-menu .menu-items li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 77%;
  text-decoration: none;
}

.page-sidebar .sidebar-menu .menu-items > li {
  position: relative;
  display: flex;
  padding: 5px 10px 5px 20px;
  align-items: center;
}
.page-sidebar .sidebar-menu .menu-items > li.active,
.page-sidebar .sidebar-menu .menu-items > li:hover {
  background-color: #f5f7f9;
}

.page-sidebar .sidebar-menu .menu-items > li.active:before,
.page-sidebar .sidebar-menu .menu-items > li:hover:before {
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #017d34;
  content: "";
  left: 0;
}

.page-sidebar .sidebar-menu .menu-items > li .icon {
  width: 20px;
  height: auto;
}
.page-sidebar .sidebar-menu .menu-items > li > a {
  font-family: Arial, sans-serif;
  display: inline-block;
  padding-left: 10px;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  clear: both;
}

.page-sidebar .sidebar-menu .menu-items > li > svg.open {
  transform: rotate(-90deg);
}

.page-sidebar .sidebar-menu .menu-items > li > a > .title {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 65%;
}

.page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu {
  display: block;
}

.page-sidebar .sidebar-menu ul.sub-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-sidebar .sidebar-menu ul.sub-menu > li {
  background: 0 0;
  padding: 10px 0 10px 45px;
  margin-top: 1px;
}

.page-sidebar .sidebar-menu ul.sub-menu > li.active a {
  font-weight: bold;
}

.page-sidebar .sidebar-menu .muted {
  color: #576775;
  opacity: 0.45;
}

/* 
@media only screen and (max-width:1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none
  }
}

@media only screen and (min-width:980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none!important;
    -webkit-transform: none!important;
    -ms-transform: none!important
  }
  body.menu-pin [data-toggle-pin=sidebar]>i:before {
    content: "\f192"
  }
  body.menu-pin .page-sidebar {
    transform: translate(210px, 0)!important;
    -webkit-transform: translate(210px, 0)!important;
    -ms-transform: translate(210px, 0)!important
  }
  body.menu-pin .page-sidebar {
    width: 250px
  }
  body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px)
  }
  body.menu-behind .page-sidebar {
    z-index: 799
  }
  body.box-layout .page-sidebar {
    left: auto;
    transform: none!important;
    -webkit-transform: none!important
  }
}

@media(max-width:991px) {
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0!important;
    -webkit-transform: translate3d(0, 0px, 0px)!important;
    transform: translate3d(0, 0px, 0px)!important
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 30px 0 36px
  }
  .page-sidebar {
    display: none;
    z-index: auto
  }
  .page-sidebar.visible {
    display: block
  }
  .page-sidebar .page-sidebar-inner {
    z-index: 1;
    left: 0!important;
    width: 260px
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    top: 40px;
    bottom: 0
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul>li>a>.badge {
    display: inline-block
  }
}

@media(min-width:768px) and (max-width:1024px) and (orientation:landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000
  }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 27px
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 27px 0 31px
  }
}

@media(max-width:767px) {
  .page-sidebar .sidebar-header {
    padding: 0 12px
  }
  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 20px
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 25px 0 28px
  }
}

@media(max-width:480px) {
  body .header .header-inner .toggle-secondary-sidebar {
    font-size: 16px;
    top: 12px
  }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px
  }
} */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p,
div, header, section {
  margin: 0;
}

button, input {
  border: none;
  outline: none;
}

section {
  width: 100%;
  margin: 0;
}

.p-r {
  position: relative!important;
}

.no-padding {
  padding: 0!important;
}
.p-20 {
  padding: 20px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-40 {
  padding: 40px!important;
}
.p-t-40 {
  padding-top: 40px!important;
}
.p-r-5 {
  padding-right: 5px!important;
}
.p-l-5 {
  padding-left: 5px!important;
}

.no-margin {
  margin: 0!important;
}
.m-l-5 {
  margin-left: 5px!important;
}
.m-l-10 {
  margin-left: 10px!important;
}
.m-l-20 {
  margin-left: 20px!important;
}
.m-r-5 {
  margin-right: 5px!important;
}
.m-t-10 {
  margin-top: 10px!important;
}
.m-t-20 {
  margin-top: 20px!important;
}
.m-t--20 {
  margin-top: -20px!important;
}
.m-t-40 {
  margin-top: 40px!important;
}
.m-t-5 {
  margin-top: 5px!important;
}
.m-b-5 {
  margin-bottom: 5px!important;
}
.m-b-10 {
  margin-bottom: 10px!important;
}
.m-b-20 {
  margin-bottom: 20px!important;
}
.m-b-40 {
  margin-bottom: 40px!important;
}
.m-b-50 {
  margin-bottom: 50px!important;
}

.no-border {
  border: none!important;
}
.no-wrap {
  flex-wrap: nowrap!important;
}
.wrap {
  flex-wrap: wrap!important;
}
.flex {
  display: flex!important;
}
.flex-h-center {
  justify-content: center!important;
}
.flex-v-center {
  align-items: center!important;
}
.pointer {
  cursor: pointer!important;
}
.underline {
  text-decoration: underline!important;
}
.center-text {
  text-align: center!important;
}
.right-text {
  text-align: right!important;
}
.space-between {
  justify-content: space-between!important;
}
.float-right {
  float: right!important;
}
.inline-block {
  display: inline-block!important;
}
.bold-text {
  font-weight: bold!important;
}

.max-w-400 {
  max-width: 400px!important;
}

.max-w-500 {
  max-width: 500px!important;
}

.max-w-800 {
  max-width: 800px!important;
}

.col-1, .col-2, .col-3, .col-4,
.col-5, .col-6, .col-half, .col-7, .col-8,
.col-9, .col-10, .col-11, .col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6, .col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12, .w-100 {
  width: 100%!important;
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url(/static/media/banner.3ed1fa96.png);
  background-color: #1C811C55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #C6C6C6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017D34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017D3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #FFFFFF;
  box-shadow: 5px 3px 22px #958E8E29;
  border: 1px solid #C6C6C6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1C811C;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #C6C6C6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1C811C1F;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #C6C6C6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1C811C;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px!important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }

  
}

.show {
  display: block !important;
}

.portal {
  padding-left: 260px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f5f7f9;
}

.san-sherif {
  font-family: sans-serif !important;
}

/* HEADER STYLING */
header {
  background-color: #1c811c;
  color: #fff;
  width: calc(100% - 260px);
  z-index: 100;
  height: 62px;
  position: fixed;
}
.header-content {
  padding: 10px 20px !important;
}
.search-box {
  width: 260px;
}

.modal-border {
  border: 1px solid black;
}
.search-box input {
  background-color: #00000000;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}

.table-input {
  /* background-color: #00000000; */
  color: black;
  padding: 5px 5px 5px 5px;
  width: 70px;
  height: 40px;
  border: 1px solid black;
}

.search-box input::-webkit-input-placeholder {
  color: #bcbccb;
}

.search-box input:-ms-input-placeholder {
  color: #bcbccb;
}

.search-box input::-ms-input-placeholder {
  color: #bcbccb;
}

.search-box input::placeholder {
  color: #bcbccb;
}
header .notification svg {
  color: #bcbccb;
  width: 23px;
  height: auto;
}
header .notification span.indicator {
  width: 9px;
  height: 9px;
  position: absolute;
  background-color: #ffc06a;
  border: 1px solid #fff;
  border-radius: 50%;
  right: 11px;
  top: 2px;
}
.header-right .right-item {
  border-right: 1px solid #c6c6d68d;
  padding: 5px 10px;
}
.header-right .right-item:last-child {
  border-right: none;
  padding: 5px 0 5px 10px;
}
header .nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
header .nav-menu li {
  padding: 0 10px;
  font-size: 0.8rem;
}
.white-background {
  background-color: white;
}
.user-avater-details p {
  line-height: 0.9rem;
}
.user-avater-details .name {
  font-size: 0.7rem;
  font-weight: 700;
}
.user-avater-details .role {
  font-size: 0.6rem;
}
.user-avater-details img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.user-avater-details .arrow svg {
  width: 19px;
  height: auto;
  color: #e8a85f;
}
/* END OF HEADER STYLING */

.main-content {
  padding-top: 90px;
  min-height: calc(100vh - 58px);
  height: 100%;
}

/* LOCATION DETAILS */
.location-details p {
  line-height: 0.9rem;
}
.location-details .location {
  font-size: 0.7rem;
  font-weight: 700;
}
.location-details .login-time {
  font-size: 0.55rem;
}
.location-details img {
  width: 21px;
  height: auto;
}
/* END OF LOCATION DETAILS */

/* SEARCH INPUT STYLING */
.search-input {
  border: 1px solid #1c801c;
  padding: 5px 10px;
  background-color: #d5ffd5;
  border-radius: 3px;
  width: 350px;
}
.search-input input {
  border: none;
  background-color: #00000000;
  padding: 5px;
  width: 100%;
}
/* END OF SEARCH INPUT STYLING */

/* TABLE STYLING */
.table-view {
  display: table;
  width: 100%;
  border-spacing: 0 10px;
}
.table-view .table-header {
  display: table-header-group;
  background-color: #d5ffd5;
  font-weight: bold;
}
.table-view .table-body {
  display: table-row-group;
}
.table-view .table-row {
  display: table-row;
  /* overflow: scroll; */
}
.table-view .table-body .table-row {
  background-color: #fff;
  border-top: 10px solid transparent;
}
.table-view .table-header .th,
.table-view .table-body .table-row .td {
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  border-collapse: collapse;
}
.table-view .table-row .th:first-child {
  width: 30%;
}
.table-view .table-row .th:last-child button {
  float: right;
}
.table-view .table-row .td:last-child {
  /* text-align: right; */
}

.cloak {
  background-color: #f5f7f9;
}

button.add-button {
  border: 1px solid #1c811c;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
button.add-button svg {
  font-size: 1.5rem;
  color: #1c811c;
}

.icon {
  color: #1c811c;
  width: 20px;
  height: auto;
}

.icon-delete {
  background-color: #ff121b45;
  padding: 8px 8px 4px;
}

.icon-edit {
  background-color: #1c811c45;
  padding: 8px 8px 4px;
}

.actions {
  display: flex !important;
  justify-content: space-between;
}

.actions button {
  background-color: #1c811c45;
  border: none;
  padding: 8px 8px 4px;
}

.actions-button {
  background-color: #1c811c45;
  border: none;
  padding: 9px 10px;
  cursor: pointer;
}

.actions button.deactivate {
  background-color: #ff121b45;
  padding: 0.625rem;
  border: 1px solid red;
  color: #000;
  cursor: pointer;
}

.dashboard-table {
  overflow-x: scroll;
}

.actions button.activate {
  background-color: #1c811c45;
  padding: 0.625rem;
  border: 1px solid green;
  color: #000;
  cursor: pointer;
}

.actions button.del {
  background-color: #ff121b45;
}

.actions button.power {
  background-color: skyblue;
}

.actions button.power svg {
  color: #fff;
}

.actions button svg {
  color: #1c811c;
  width: 20px;
  height: auto;
}

.green-border {
  border: 1px solid green !important;
  cursor: pointer;
}

.p-10 {
  padding: 10px;
}
/* END OF TABLE STYLING */

/* OVERLAY */
.message-overlay,
.loading-overlay,
.overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1c811c66;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}
.message-overlay {
  background-color: #fff;
}
svg.close-btn {
  top: 15px;
  right: 15px;
  position: fixed;
  font-size: 5rem;
  color: #fff;
  background-color: #1c811ccc;
  border: 1px solid;
  box-shadow: 0 0 2px black;
  z-index: 1;
  transition: all 0.3s ease-out;
}
svg.close-btn:hover {
  background-color: #c4303566;
}
.loading-overlay {
  z-index: 1001;
}
.loading-inner-box,
.modal-box {
  margin: 0 auto;
  position: unset;
  background-color: #fff;
}
.loading-inner-box {
  padding: 50px 20px;
  width: 250px;
  text-align: center;
}
/* END OF OVERLAY STYLING */

/* FORM INPUTS STYLING */
.form-group {
  position: relative;
}
.form-group label {
  font-size: 0.75rem;
  color: #999;
}
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  height: 50px;
  padding: 10px 10px 10px 0;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  color: #777;
}
.form-group select::-ms-expand {
  display: none;
}
.form-group textarea {
  height: auto;
  line-height: 1.4rem;
}
.form-group select:disabled,
.form-group input:disabled {
  color: rgb(84, 84, 84);
  cursor: default;
  background-color: rgb(235, 235, 228);
}
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-width: 2px;
}
.form-group-search input {
  padding-right: 20px;
}
.form-group-search svg {
  position: absolute;
  right: 0;
  bottom: 13px;
}
/* END OF FORM INPUTS STYLING */

/* BUTTON STYLES */
.btn {
  background-color: #1c811c;
  padding: 8px 15px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.btn:hover {
  background-color: #1c811c88;
}
i.btn {
  font-style: normal;
}
.btn-light {
  background-color: #1c811c1c;
  border: 1px solid#1C811C;
  padding: 7px 15px;
  color: #555;
}
.btn-large {
  padding: 14px;
}
.btn-large.btn-light {
  padding: 13px 14px;
}
.btn-round {
  border-radius: 50px !important;
}
.btn-gray {
  background-color: #838383;
  color: #e8e8e8;
}
.btn-small {
  padding: 5px;
  margin: 2px 0;
}
.btn svg {
  font-size: 1rem;
  color: #1c811c;
}
.btn svg.right {
  margin-left: 10px;
}
.btn svg.left {
  margin-right: 10px;
}
.btn-group {
  border-radius: 10px;
  display: flex;
}
.btn-group button {
  border-radius: 0;
}
.btn-group button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.btn-group button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.btn-del {
  background-color: #de2222;
}
.btn-del:hover {
  background-color: #de2222aa;
}
button:disabled,
button:disabled:hover {
  cursor: default;
  background-color: #999;
}
button:focus {
  outline: none;
}
/* END 0F BUTTON STYLES */

/* FORM WITH TABS STYLING */
.tab-btns {
  border-bottom: 1px solid #e9e9f0;
}
.tab-btns button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid#E9E9F0;
  border-bottom: none;
  background-color: #f8f8f8;
  color: #43425d;
  padding: 10px;
  margin-right: 5px;
}
.tab-btns button:last-child {
  margin-right: 0;
}
.tab-btns button.active {
  background-color: #fff;
}
.user-full-details .left {
  width: calc(100% - 300px);
}
.user-full-details .left .content {
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000a;
}
.user-full-details .right {
  width: 300px;
  padding: 20px 10px;
}
.profile-brief img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.profile-brief .brief {
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 20px 10px;
  font-family: sans-serif;
}
.profile-brief .brief hr {
  width: calc(100% + 20px);
  margin: 10px -10px;
}
.profile-brief .brief p {
  font-size: 0.8rem;
}

.custom-header {
  background-color: #d5ffd5;
  font-weight: bold;
  width: 100%;
  padding: 0.7rem;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid grey;
  width: 30px;
  height: 30px; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.stat-card-container{margin-top:1rem;display:grid;grid-template-columns:repeat(2, auto);grid-gap:1rem;gap:1rem}.stat-card-container .stat-card .stat-card-header{display:flex;justify-content:space-between;background-color:#fff;box-shadow:0 0 3px 1px #f3efef;padding:.8rem;width:100%}.stat-card-container .stat-card .stat-card-header span{display:inline-block}.stat-card-container .stat-card .stat-card-divider{border-top:3px solid green}.stat-card-container .stat-card-body{background-color:#fff;box-shadow:0 0 3px 1px #f3efef;padding:.8rem;width:100%}.stat-card-container .stat-card-body .stat-card-item{display:flex;justify-content:space-between}.stat-card-container .stat-card-body .stat-card-item-divider{border-top:1px solid #000}.stat-card-container .stat-card-body span{display:inline-block}
.custom-search .ant-input{height:2rem;width:15rem;padding:.5rem;transition:border linear 1s}.custom-search .ant-input:hover{border:1px solid #1c811c}.custom-search .ant-input:focus{border:1px solid #1c811c}.custom-search .ant-input-group-addon{display:inline-block}.custom-search .ant-input-group-addon>.ant-input-search-button{height:2rem;background-color:#1c811c;color:#fff;cursor:pointer}

