.custom-search {
  & .ant-input {
    height: 2rem;
    width: 15rem;
    padding: 0.5rem;
    transition: border linear 1s;

    &:hover {
      border: 1px solid #1c811c;
    }

    &:focus {
      border: 1px solid #1c811c;
    }
  }

  .ant-input-group-addon {
    display: inline-block;

    & > .ant-input-search-button {
      height: 2rem;
      background-color: #1c811c;
      color: #fff;
      cursor: pointer;
    }
  }
}
